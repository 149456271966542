<template>
  <div class="auth__area trial__page__verification_code">
    <Loader full :loading="isLoading" />

    <h1 v-if="product === 'online'">{{ $t('FileCloud Online Trial') }}</h1>
    <h1 v-else-if="product === 'server'">{{ $t('FileCloud Server Trial') }}</h1>
    <h1 v-else-if="product === 'community'">
      {{ $t('FileCloud Community Edition') }}
    </h1>
    <h4
      v-html="
        $t(
          'Please enter the code you received in your email <b>{email}</b> in the box below.',
          { email: user.email }
        )
      "
    />

    <form class="trial__page__online_trial" @submit.prevent="onSubmit">
      <transition name="fade">
        <InfoBox v-if="isCodeResent" type="info" class="pt-0 pb-2">
          <span>
            {{
              $t(
                'We just sent your verification code, please check your inbox and spam folder.'
              )
            }}
          </span>
        </InfoBox>
      </transition>

      <div class="row mb-4">
        <InputBox
          id="code"
          v-model="form.code"
          :errors="errors"
          :focus="true"
          :label="$t('Verification Code')"
          type="text"
        >
          <a
            slot="input-append"
            class="d-flex align-items-center px-3"
            :class="{
              disabled: timeout > 0,
            }"
            @click="resendCode"
          >
            {{ timeout > 0 ? $tc('Resend ({count}s)', timeout) : $t('Resend') }}
          </a>
        </InputBox>
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
          mx-5
          mb-3
          pt-3
          mx-lg-0
        "
      >
        <div class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0">
          <button
            class="btn btn-link mr-4"
            type="button"
            @click="$emit('changeStep', { name: product })"
          >
            {{ $t('Back') }}
          </button>
          <button
            v-if="product === 'community'"
            type="submit"
            class="btn btn-primary col"
          >
            {{ $t('Start') }}
          </button>
          <button v-else type="submit" class="btn btn-primary col">
            {{ $t('Start Free Trial') }}
          </button>
        </div>

        <div class="col-sm-12 col-lg-4">
          <SupportBlock minified />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import Loader from 'common/components/Loader';
import SupportBlock from '@/components/Partials/SupportBlock';
import InfoBox from 'common/components/InfoBox.vue';

export default {
  name: 'VerificationCode',
  components: {
    InputBox,
    Loader,
    SupportBlock,
    InfoBox,
  },
  props: {
    initialTimeout: {
      type: Number,
      default: 0,
    },
    product: {
      type: String,
      default: '',
    },
    noHistory: Boolean,
  },
  data() {
    return {
      form: {
        code: '',
      },
      timeout: 0,
      interval: null,
      errors: {},
      attributes: {},
      isCodeResent: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.core['user'];
    },
    isLoading() {
      return (
        this.$store.state.loading['core/resendCode'] ||
        this.$store.state.loading['core/trialConfirm'] ||
        this.$store.state.loading['core/resumeSession']
      );
    },
  },
  mounted() {
    /*  if (this.$route.params.product != 'server') {
      this.$router.push({name: 'login'});
    } */

    if (this.$route) {
      const { attributes } = this.$route.params;

      // check params in the url
      if (attributes) {
        this.onAutoSubmit(attributes);
      }
    }

    if (!this.user.email && !this.$route.params?.attributes) {
      this.$emit('changeStep', { name: 'login' });
    }

    // handle timeout countdown
    this.timeout = this.initialTimeout;
    this.interval = setInterval(() => {
      this.timeout--;
    }, 1000);
  },

  methods: {
    async onSubmit() {
      this.errors = {};

      if (!this.attributes.code && !this.form.code) {
        this.errors = {
          code: {
            message: this.$t(
              "If you haven't got the code yet, check your spam folder or try clicking 'Resend' to send a new code"
            ),
          },
        };
        return;
      }

      const response = await this.$store.dispatch('core/trialConfirm', {
        email: this.attributes.email ? this.attributes.email : this.user.email,
        code: this.form.code ? this.form.code : this.attributes.code,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'object'
            ? response.error
            : { code: { message: response.data.msg } };
        return;
      }

      this.$store.dispatch('core/sendAnalyticsEvent', {
        category: 'cerebro-trial',
        action: `confirmed-${
          this.product === 'server' ? 'onpremise' : this.product
        }`, // confirm-product
        label: this.attributes.email ? this.attributes.email : this.user.email, // customer email
      });

      if (this.product === 'server' || this.product === 'community') {
        // in modal
        if (this.noHistory && window.parent) {
          window.parent.postMessage(
            JSON.stringify({
              action: 'trial-open',
              url: `https://portal.getfilecloud.com/ui/user/index.html#/?auth=${response.data.token}`,
            }),
            '*'
          );
          return;
        }

        // if internal
        const authResponse = await this.$store.dispatch('core/resumeSession', {
          token: response.data.token,
        });

        if (!authResponse.ok) {
          this.errors = { code: { message: response.error } };
        }

        this.$router.push('/');
      } else if (this.product === 'online') {
        const { token, sitetoken } = response.data;
        const fullToken = btoa(`${token}|${sitetoken}`);

        // go to intro
        // in modal
        if (this.noHistory && window.parent) {
          window.parent.postMessage(
            JSON.stringify({
              action: 'trial-open',
              url: `https://portal.getfilecloud.com/ui/user/index.html#/intro/${fullToken}`,
            }),
            '*'
          );
        } else {
          this.$router.push(`/intro/${fullToken}`);
        }
      }
    },
    async onAutoSubmit(attributes) {
      try {
        const [email, code] = atob(attributes).split('/');
        this.attributes = {
          email,
          code,
        };
        this.onSubmit({ email, code });
      } catch (e) {
        this.errors = {
          code: { message: 'Failed to decode the validation token.' },
        };
      }
    },
    async resendCode() {
      const response = await this.$store.dispatch(
        'core/resendCode',
        this.attributes.email ? this.attributes.email : this.user.email
      );
      this.isCodeResent = true;
      this.timeout = response;
      this.form.code = '';
      this.errors = {};
    },
  },
};
</script>
