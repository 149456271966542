var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth__area trial__page__verification_code"},[_c('Loader',{attrs:{"full":"","loading":_vm.isLoading}}),(_vm.product === 'online')?_c('h1',[_vm._v(_vm._s(_vm.$t('FileCloud Online Trial')))]):(_vm.product === 'server')?_c('h1',[_vm._v(_vm._s(_vm.$t('FileCloud Server Trial')))]):(_vm.product === 'community')?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('FileCloud Community Edition'))+" ")]):_vm._e(),_c('h4',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'Please enter the code you received in your email <b>{email}</b> in the box below.',
        { email: _vm.user.email }
      )
    )}}),_c('form',{staticClass:"trial__page__online_trial",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isCodeResent)?_c('InfoBox',{staticClass:"pt-0 pb-2",attrs:{"type":"info"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'We just sent your verification code, please check your inbox and spam folder.' ))+" ")])]):_vm._e()],1),_c('div',{staticClass:"row mb-4"},[_c('InputBox',{attrs:{"id":"code","errors":_vm.errors,"focus":true,"label":_vm.$t('Verification Code'),"type":"text"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}},[_c('a',{staticClass:"d-flex align-items-center px-3",class:{
            disabled: _vm.timeout > 0,
          },attrs:{"slot":"input-append"},on:{"click":_vm.resendCode},slot:"input-append"},[_vm._v(" "+_vm._s(_vm.timeout > 0 ? _vm.$tc('Resend ({count}s)', _vm.timeout) : _vm.$t('Resend'))+" ")])])],1),_c('div',{staticClass:"\n        row\n        no-gutters\n        flex-row-reverse\n        d-sm-flex\n        justify-content-between\n        align-items-center\n        mx-5\n        mb-3\n        pt-3\n        mx-lg-0\n      "},[_c('div',{staticClass:"d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"},[_c('button',{staticClass:"btn btn-link mr-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('changeStep', { name: _vm.product })}}},[_vm._v(" "+_vm._s(_vm.$t('Back'))+" ")]),(_vm.product === 'community')?_c('button',{staticClass:"btn btn-primary col",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Start'))+" ")]):_c('button',{staticClass:"btn btn-primary col",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Start Free Trial'))+" ")])]),_c('div',{staticClass:"col-sm-12 col-lg-4"},[_c('SupportBlock',{attrs:{"minified":""}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }