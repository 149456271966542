<template>
  <div class="auth__area trial__page__online_trial">
    <Loader full :loading="isLoading" />

    <h1 v-if="$refs.steps && $refs.steps.currentStepId === 2">
      {{ $t('Tell us about your trial...') }}
    </h1>
    <h1 v-else-if="!isPending">{{ $t('FileCloud Online Trial') }}</h1>
    <h4
      v-html="
        $t(
          'We host FileCloud for you on compliant (HIPAA/ITAR/GDPR) infrastructure. <b>No setup needed.</b>'
        )
      "
    />

    <StepsWrapper ref="steps">
      <Step :title="$t('Basic Information')">
        <form
          v-if="!isPending"
          class="trial__page__online_trial"
          @submit.prevent="validateFormStep('basicInformation')"
        >
          <div v-if="errors._message" class="alert alert-danger">
            {{ errors._message }}
          </div>

          <div class="form-row">
            <InputBox
              id="name"
              v-model="form.name"
              :label="$t('Full Name')"
              :focus="true"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('name')"
            />
            <InputBox
              id="email"
              v-model="form.email"
              :label="$t('Email Address')"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('email')"
            />
          </div>

          <div class="form-row">
            <PhoneInputBox
              id="phone"
              ref="phone"
              v-model="form.phone"
              :label="$t('Phone Number')"
              class="col-md-6"
              :errors="errors"
              @input="removeError('phone')"
            />

<!--            <v-popover-->
<!--              offset="['0', -30]"-->
<!--              placement="bottom-start"-->
<!--              class="region-map-popover"-->
<!--            >-->
              <SelectBox
                id="trialRegion"
                v-model = form.trialRegion
                :label="$t('Trial Location')"
                input-class="text-muted"
                class="px-0"
              >

                <option :label="$t('US East')" :value="$t('US East')" />
                <option :label="$t('Ireland')" :value="$t('Ireland')" />
                <option :label="$t('Singapore')" :value="$t('Singapore')" />
              </SelectBox>

<!--              <template #popover>-->
<!--                <div class="map-container">-->
<!--                  <h3>-->
<!--                    {{-->
<!--                      $t(-->
<!--                        'Your trial will be provisioned in the US East Region. When you sign up for FileCloud, you will have the option to choose from the regions below:'-->
<!--                      )-->
<!--                    }}-->
<!--                  </h3>-->
<!--                  <RegionBox />-->
<!--                </div>-->
<!--              </template>-->
<!--            </v-popover>-->
          </div>

          <div class="form-row mb-3">
            <InputBox
              id="siteUrl"
              v-model="form.siteUrl"
              :label="$t('Choose your own FileCloud site URL')"
              input-class="pl-1"
              type="text"
              class="col-md-12 mb-0"
              :errors="errors"
              @copy="onCopyURI"
              @input="removeError('siteUrl')"
            >
              <div slot="input-prepend" class="pr-0">
                {{ protocol }}
              </div>
              <div slot="input-append" class="color-primary">
                {{ domain }}
              </div>
            </InputBox>
          </div>

          <div class="form-row">
            <div
              class="form-group form-checkbox pt-1 mb-0 pl-1 flex-wrap"
              :class="{ ['is-invalid']: errors['tos'] }"
            >
              <Checkbox
                id="tos"
                v-model="form.tos"
                :errors="errors"
                :aria-label="$t('I have read and agree to the terms of use')"
                @input="removeError('tos')"
              />
              <label for="tos"
                >I have read and agree to the
                <a
                  href="https://www.filecloud.com/filecloud-online-tos/"
                  target="_blank"
                  >Terms of Service</a
                > and 
                <a
                  href="https://www.filecloud.com/product-privacy-notice/"
                  target="_blank"
                  >Product Privacy Notice</a
                ></label
              >
              <small v-if="errors['tos']">{{
                $t('You must read and agree in order to proceed')
              }}</small>
            </div>
          </div>

          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                class="btn btn-link mr-4"
                type="button"
                @click="$emit('changeStep', { name: 'welcome' })"
              >
                {{ $t('Back') }}
              </button>
              <button type="submit" class="btn btn-primary col">
                {{ $t('Next') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified />
            </div>
          </div>
        </form>

        <form v-else class="trial__page__online_trial">
          <h4 class="text-pending">
            {{ pendingMessage }}
          </h4>

          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                class="btn btn-link ml-auto"
                type="button"
                @click="$emit('changeStep', { name: 'welcome' })"
              >
                {{ $t('Back') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified />
            </div>
          </div>
        </form>
      </Step>

      <Step :title="$t('General Information')">
        <form
          class="intro-body has-transition"
          @submit.prevent="validateFormStep('generalInformation', onSubmit)"
        >
          <div v-if="errors._message" class="alert alert-danger">
            {{ errors._message }}
          </div>
          <div class="form-row">
            <InputBox
              id="company"
              v-model="form.company"
              :label="$t('Company')"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('company')"
            />

            <MultiColumnSelect
            id="industry"
            v-model="form.industry"
            class="col-md-6"
            :label="$t('Industry?')"
            placeholder="Please Select"
            :errors="errors"
            :column1="[
             { value: 'Apparel', text: 'Apparel' },
             { value: 'Architecture', text: 'Architecture' },
             { value: 'Automotive', text: 'Automotive' },
             { value: 'Aviation / Aerospace', text: 'Aviation / Aerospace' },
             { value: 'Chemicals', text: 'Chemicals' },
             { value: 'Construction', text: 'Construction' },
             { value: 'Defense / Space', text: 'Defense / Space' },
             { value: 'Education', text: 'Education' },
             { value: 'Energy/Oil & Gas', text: 'Energy/Oil & Gas' },
             { value: 'Engineering', text: 'Engineering' },
             ]"
             :column2="[
            { value: 'Entertainment', text: 'Entertainment' },
            { value: 'Financial / Banking', text: 'Financial / Banking' },
            { value: 'Food & Beverage', text: 'Food & Beverage' },
            { value: 'Government - Federal/City/State', text: 'Government - Federal/City/State', },
            { value: 'Healthcare/Life Sciences', text: 'Healthcare/Life Sciences', },
            { value: 'Information Technology/Software Services',  text: 'Information Technology/Software Services', },
            { value: 'Insurance', text: 'Insurance' },
            { value: 'Legal', text: 'Legal' },
            ]"
            :column3="[
            { value: 'Machinery', text: 'Machinery' },
            { value: 'Manufacturing/Heavy Industry', text: 'Manufacturing/Heavy Industry', },
            { value: 'Media/Publishing', text: 'Media/Publishing' },
            { value: 'Not For Profit', text: 'Not For Profit' },
            { value: 'Retail/Wholesale/E-commerce', text: 'Retail/Wholesale/E-commerce', },
            { value: 'Telecommunications', text: 'Telecommunications' },
            { value: 'Utilities', text: 'Utilities' },
            { value: 'Other', text: 'Other' }, //updated
            ]"
            @input="removeError('industry')"
            />
            </div>

          <div class="form-row">
            <MultiSelect
              id="objectives"
              v-model="form.objectives"
              :label="$t('What are your objectives with FileCloud?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              :options="[
                { value: 'Secure File Sharing', text: 'Secure File Sharing' },
                { value: 'Compliance', text: 'Compliance' },
                {
                  value: 'Central Data Repository',
                  text: 'Central Data Repository',
                },
                {
                  value: 'Infrastructure Modernization',
                  text: 'Infrastructure Modernization',
                },
                {
                  value: 'Remote Access without VPN',
                  text: 'Remote Access without VPN',
                },
                { value: 'Other', text: 'Other' },
              ]"
              @input="removeError('objectives')"
            />

            <ReferralSelectBox
              id="referral"
              v-model="form.referral"
              :label="$t('How did you hear about us?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              :options="[
                {
                  value: 'Referral / External File Share',
                  text: 'Referral / External File Share',
                },
                { value: 'Existing Customer', text: 'Existing Customer' },
                { value: 'Web Search', text: 'Web Search' },
                { value: 'Other', text: 'Other' },
              ]"
              @input="removeError('referral')"
            />
          </div>

          <div class="form-row">
            <SelectBox
              id="time_frame"
              v-model="form.time_frame"
              :label="$t('Purchase Time Frame?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              @input="removeError('time_frame')"
            >
              <option
                v-for="item in purchasetimeframe"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </SelectBox>

            <SelectBox
              id="planned_users"
              v-model="form.planned_users"
              :label="$t('Number of planned users?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              @input="removeError('planned_users')"
            >
              <option v-for="item in licenseamounts" :key="item" :value="item">
                {{ item }}
              </option>
            </SelectBox>
          </div>

          <div class="form-row">
            <div class="break"></div>
            <!--hr -->
          </div>
          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                class="btn btn-link mr-4"
                type="button"
                @click="$refs.steps.previousStep()"
              >
                {{ $t('Back') }}
              </button>
              <button type="submit" class="btn btn-primary col">
                {{ $t('Next') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified />
            </div>
          </div>
        </form>
      </Step>
    </StepsWrapper>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import MultiColumnSelect from '@/components/MultiColumnSelect';
import MultiSelect from '@/components/MultiSelect';
import SupportBlock from '@/components/Partials/SupportBlock';
import PhoneInputBox from '@/components/PhoneInputBox';
import ReferralSelectBox from '@/components/ReferralSelectBox';
import SelectBox from '@/components/SelectBox';
import licenseamounts from '@/constants/licenseamounts';
import purchasetimeframe from '@/constants/purchasetimeframe';
import CountriesList from '@/constants/salesforce-countries';
import Checkbox from 'common/components/Checkbox';
import Loader from 'common/components/Loader';
import StepsWrapper from '@/components/TrialSignup/TrialFormSteps/StepsWrapper';
import Step from '@/components/TrialSignup/TrialFormSteps/Step';

export default {
  name: 'OnlineTrial',
  components: {
    InputBox,
    SelectBox,
    PhoneInputBox,
    Loader,
    SupportBlock,
    Checkbox,
    MultiColumnSelect,
    MultiSelect,
    ReferralSelectBox,
    StepsWrapper,
    Step,
  },
  props: {
    product: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
      protocol: 'https://',
      domain: '.filecloudonline.com',
      form: {
        name: '',
        email: '',
        phone: '',
        siteUrl: '',
        trialRegion:this.$t('US East'),
        company: '',
        objectives: '',
        industry: '',
        referral: '',
        time_frame: '',
        planned_users: '',
        recaptcha_response: '',
      },
      isPending: false,
      pendingMessage: null,
      purchasetimeframe,
      licenseamounts,
      errors: {},
      schemas: {
        basicInformation: {
          name: {
            type: 'string',
            empty: false,
          },
          email: {
            type: 'email',
            empty: false,
          },
          phone: {
            type: 'string',
            empty: false,
            rules: [
              {
                name: 'validUSPhone',
                validator: () => {
                  if (this.$refs.phone.activeCountry.iso2.toLowerCase() === 'us' && !this.$refs.phone.isValidUSNumber){
                    return { isValid: false, error: 'Invalid phone number. A valid US number has 10 digits.' }
                  } else {
                    return { isValid: true }
                  }
                }
              }
            ]
          },
          siteUrl: {
            type: 'string',
            empty: false,
          },
          trialRegion: {
            type: 'string',
            empty: true
          },
          tos: {
            type: 'equal',
            value: true,
            strict: true,
            messages: {
              equalValue: 'You must read and agree in order to proceed',
            },
          },
        },
        generalInformation: {
          company: {
            type: 'string',
            empty: false,
          },
          industry: {
            type: 'string',
            empty: false,
          },
          objectives: {
            type: 'string',
            empty: false,
          },
          referral: {
            type: 'string',
            empty: false,
          },
          time_frame: {
            type: 'string',
            empty: false,
          },
          planned_users: {
            type: 'string',
            empty: false,
          },
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.core['user'];
    },
    isLoading() {
      return this.$store.state.loading['core/trialSignup'];
    },
  },
  watch: {
    'form.objectives': function (newValue) {
      if (newValue.includes('other')) {
        this.showOtherObjectivesInput = true;
      } else {
        this.showOtherObjectivesInput = false;
      }
    },
  },
  mounted() {
    this.setUserGeo();
  },
  methods: {
    onSelect(page) {
      this.$emit('changePage', page);
    },
    removeError(field) {
      if (this.form[field]) {
        delete this.errors[field];
      }
    },
    onCopyURI(e) {
      const selection = document.getSelection().toString();
      const inputText = e.target.value;
      if (selection == inputText) {
        const URI = this.protocol + selection + this.domain;
        e.clipboardData.setData('text/plain', URI);
        e.preventDefault();
      }
    },
    async setUserGeo() {
      if (this.$store.state.core.isGeoLocationScriptLoaded) {
        return;
      }
      await this.$store.dispatch('core/getUserGeo');
      const response = await this.$store.state.core.userGeo;
      let domain = '';
      if (response.country == 'China') {
        domain = 'www.recaptcha.net';
      } else {
        domain = 'www.google.com';
      }
      let recaptchaScript = document.createElement('script')
      let scriptSrc = `https://${domain}/recaptcha/api.js?render=6Lds_HMpAAAAAG_s_ViHbX191g9hvQxXbhvtOTxV`;
      recaptchaScript.setAttribute('src', scriptSrc);
      document.head.appendChild(recaptchaScript);
      this.$store.state.core.isGeoLocationScriptLoaded = true;
    },
    getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6Lds_HMpAAAAAG_s_ViHbX191g9hvQxXbhvtOTxV', { action: 'submit' })
            .then((token) => { resolve(token); });
        });
      })
    },
    async onSubmit() {
      this.form.recaptcha_response = await this.getRecaptchaToken();
      // if no country code included
      if (
        this.form.phone &&
        this.form.phone[0] !== '+' &&
        this.$refs.phone.activeCountry.dialCode
      ) {
        this.form.phone = `+${this.$refs.phone.activeCountry.dialCode} ${this.form.phone}`;
      }

      const country = this.$refs.phone.activeCountry;

      const response = await this.$store.dispatch('core/trialSignup', {
        ...this.form,
        country: CountriesList[country.iso2]
          ? CountriesList[country.iso2]
          : country.name,
        producttype: this.product === 'online' ? 1 : 0,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.errors = {};

      if (response.data?.pending) {
        this.pendingMessage = response.data.message;
        this.isPending = true;
      } else {
        this.$emit('changeStep', { name: 'confirm', product: 'online' });

        this.$store.dispatch('core/sendAnalyticsEvent', {
          category: 'cerebro-trial',
          action: 'signup-online',
          label: this.form.email,
        });
      }
    },
    toggleObjective(value) {
      if (this.form.objectives.includes(value)) {
        this.form.objectives = this.form.objectives.filter(
          (objective) => objective !== value
        );
      } else {
        this.form.objectives.push(value);
      }
    },
    toggleObjectivesInput() {
      this.showOtherObjectivesInput = !this.showOtherObjectivesInput;
    },
    validateFormStep(formTitle, callback) {
  this.errors = {};
  callback = callback || this.$refs.steps.nextStep;
  const schema = this.schemas[formTitle];
  
  if (!schema) return callback()
  
  const isValid = this.$refs.steps.validator.validate(this.form, schema);

  if(formTitle === 'basicInformation' && this.$refs.phone.activeCountry.iso2.toLowerCase() === 'us' && !this.$refs.phone.isValidUSNumber){
    this.errors.phone = { message: 'Invalid phone number. A valid US number has 10 digits.' };
    return
  }

  if (isValid === true) return callback();

  this.errors = this.$refs.steps.mapErrors(isValid);
    },
  },
};
</script>
<style lang="scss" scoped>
.break {
  width: 592.56px;
  border-top: 1px solid #d2d2d2;
  margin-top: 25px;
  margin-bottom: 25px;
}

.hide-chevron {
  ::v-deep .fa-chevron-down {
    display: none;
  }
}
</style>
